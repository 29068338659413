// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Button, CssBaseline, Dialog, DialogContent, DialogTitle, Grid, ThemeProvider } from '@mui/material';
import { ErrorCode } from '../../utils/errorUtils';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MismatchAccountDetails } from './ErrorPages/MismatchAccountDetails';
import { AccountDoesNotExist } from './ErrorPages/AccountDoesNotExist';
import { UnknownError } from './ErrorPages/UnknownError';
import { alertTheme } from '../Themes/Themes';
import { t } from '../../i18n/i18n';
import Logo from '../Logo';

// Render the error page based on error code extracted from the path
const RenderErrorPage = ({ errorCode }: { errorCode: string | undefined }) => {
  switch (errorCode) {
    case ErrorCode.AccountDoesNotExist:
      return <AccountDoesNotExist />;
    case ErrorCode.MismatchAccountDetails:
      return <MismatchAccountDetails />;
    default:
      return <UnknownError />;
  }
};

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const { errorCode } = useParams();

  const handleReturn = () => {
    if (!redirectTo) {
      return navigate({ pathname: `/error/${ErrorCode.Unknown}`, search: searchParams.toString() });
    }
    window.location.href = redirectTo;
  };

  return (
    <ThemeProvider theme={alertTheme}>
      <CssBaseline />
      <Dialog open>
        <Logo />
        <Grid container direction="column" alignItems="center" justifyContent="center"></Grid>
        <DialogTitle>
          <ErrorIcon sx={{ marginRight: '2px' }} />
          {t({
            defaultMessage: 'Error',
            id: 'KN7zKn',
          })}
        </DialogTitle>
        <DialogContent>
          <RenderErrorPage errorCode={errorCode} />
          {redirectTo && (
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={handleReturn}>
                {t({
                  defaultMessage: 'Try Again',
                  id: 'jsy7pk',
                })}
              </Button>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ErrorPage;
