// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import {
  Alert,
  AlertTitle,
  Box,
  Link as MuiLink,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { t } from '../../../i18n/i18n';
import { useLoginFormContext } from '../LoginForm';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '../../../i18n/languages';

const PromptPage = () => {
  const { onNavigateToConfigureMFA, backToProduct } = useLoginFormContext();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') ?? DEFAULT_LANGUAGE;

  return (
    <Box>
      <DialogTitle>
        {t({
          defaultMessage: 'Register 2-Step Authentication',
          id: 'x+ZsOB',
        })}
      </DialogTitle>
      <DialogContent>
        <Alert severity="info" variant="outlined" icon={false}>
          <AlertTitle>
            {t({
              defaultMessage: 'Keep your account secure',
              id: 'fY+l6r',
            })}
          </AlertTitle>
          {t({
            defaultMessage: `Protect your account by adding an extra layer of security. Enable 2-Step Authentication to require a unique verification code along with your password when signing in. Learn more at `,
            id: 'Xy3Hco',
          })}
          <MuiLink
            href={`/faq?section=whyMFA&&lang=${lang}`}
            target="_blank"
            rel="noopener"
            color="inherit"
            aria-label={t({
              defaultMessage: 'Read more about 2-Step Authentication',
              id: 'vNKcNi',
            })}
            underline="always">
            {t({
              defaultMessage: '2-Step Authentication',
              id: 'vsNhRD',
            })}
          </MuiLink>
          <Typography variant="body2">
            <br />
            {t({
              defaultMessage: `This step is currently optional. However, please be aware that 2-Step Authentication will become mandatory for all users at a future date.`,
              id: 'cHc2xP',
            })}
          </Typography>
        </Alert>
        <Grid container marginTop={2}>
          <Grid item xs={12}>
            <Button variant="contained" onClick={onNavigateToConfigureMFA}>
              {t({
                defaultMessage: 'Register 2-Step Authentication',
                id: 'x+ZsOB',
              })}
            </Button>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <MuiLink target="_blank" color="inherit" underline="always" variant="body2" onClick={backToProduct}>
              {t({
                defaultMessage: 'I will do it later',
                id: 'PvrI2D',
              })}
            </MuiLink>
          </Grid>
        </Grid>
      </DialogContent>
    </Box>
  );
};

export default PromptPage;
